/* imports tailwind */
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

/* navbar */
@import url("https://fonts.googleapis.com/css2?family=Jacques+Francois&family=Playfair+Display:wght@400;500&family=Roboto+Slab:wght@400;500;600&display=swap");

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: antiquewhite; */
  background-color: rgb(202, 232, 233);
  /* background-color: #c8c1af; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

/* VANAF HIER EIGEN CSS */

/* Zorgt voor initialisatie van de body app */
.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  font-size: 16px;
}
/* .header-content {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 60vh;
} */

/* Animatie navbar */

#navLink {
  position: relative;
  text-decoration: none;
  font-family: "Jacques Francois", serif;
}

#navLink:hover {
  color: #000000;
}

#navLink::before {
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #000000;
  transform: scaleX(0);
  transition: transform 0.3s ease;
}

#navLink:hover::before {
  transform: scaleX(1);
}

.App-footer {
  min-height: 50px;
  margin-top: auto !important;
}

.header {
  font-family: "Jacques Francois", serif;
  font-family: "Playfair Display", serif;
  font-family: "Roboto Slab", serif;
}

/* scrollbar */
/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #0e8c95 #ffffff;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 14px;
}

*::-webkit-scrollbar-track {
  background: rgb(202, 232, 233);
}

*::-webkit-scrollbar-thumb {
  background-color: #0e8c95;
  border-radius: 20px;
  border: 3px none #ffffff;
}

/* WAVE ANIMATION HOME PAGE */
.wave {
  animation-name: wave-animation; /* Refers to the name of your @keyframes element below */
  animation-duration: 2.5s; /* Change to speed up or slow down */
  animation-iteration-count: infinite; /* Never stop waving :) */
  transform-origin: 70% 70%; /* Pivot around the bottom-left palm */
  display: inline-block;
}

@keyframes wave-animation {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(14deg);
  } /* The following five values can be played with to make the waving more or less extreme */
  20% {
    transform: rotate(-8deg);
  }
  30% {
    transform: rotate(14deg);
  }
  40% {
    transform: rotate(-4deg);
  }
  50% {
    transform: rotate(10deg);
  }
  60% {
    transform: rotate(0deg);
  } /* Reset for the last half to pause */
  100% {
    transform: rotate(0deg);
  }
}
